import { heading_align, paragraph_align } from "../alignment/AlignmentPlugin";
import { anchor } from "../anchor";
import { inline_styles } from "../inline_styles";
import { createMarkdownParser } from "../markdown/MarkdownParser";
import { createMarkdownSerializer } from "../markdown/MarkdownSerializer";
import { soft_hyphen } from "../soft_hyphen";
// tslint:disable-next-line
var tableNodes = require("prosemirror-tables").tableNodes;
// tslint:disable-next-line
var Schema = require("prosemirror-model").Schema;
export function createSchema(options, isInlineStylesEnabled) {
    if (isInlineStylesEnabled === void 0) { isInlineStylesEnabled = false; }
    var schema = require("prosemirror-markdown").schema;
    // TODO: don't register nodes and marks that are disabled in the options
    var marks = schema.spec.marks;
    if (isInlineStylesEnabled) {
        marks = marks.addToEnd("inline_styles", inline_styles);
    }
    var nodes = schema.spec.nodes
        .append(tableNodes({
        tableGroup: "block",
        cellContent: "block",
        cellAttributes: {
            background: {
                default: null,
                // tslint:disable-next-line
                getFromDOM: function (dom) {
                    return dom.style.backgroundColor || null;
                },
                // tslint:disable-next-line
                setDOMAttr: function (value, attrs) {
                    if (value) {
                        attrs.style =
                            (attrs.style || "") + ("background-color: " + value + ";");
                    }
                }
            }
        }
    }))
        .addToEnd("soft_hyphen", soft_hyphen)
        .addToEnd("anchor", anchor)
        .update("paragraph", paragraph_align)
        .update("heading", heading_align);
    return new Schema({
        nodes: nodes,
        marks: marks
    });
}
var serializer;
var parser;
export function getDefaultSerializerParser(schema, options) {
    if (serializer == null) {
        serializer = createMarkdownSerializer(options);
        parser = createMarkdownParser(schema, options);
    }
    return [serializer, parser];
}
