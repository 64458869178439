var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getDefaultClass } from "@dc-extension-rich-text/common";
import { html_block } from "../alignment";
import { soft_hyphen_from } from "../soft_hyphen";
// tslint:disable-next-line
var markdown = require("prosemirror-markdown");
// tslint:disable-next-line
var markdownit = require("markdown-it");
// tslint:disable-next-line
var markdownItTable = require("markdown-it-table").markdownItTable;
export function createMarkdownParser(schema, options) {
    var md = markdownit("commonmark", { html: true });
    md.use(markdownItTable);
    md.inline.ruler.before("text", "soft_hyphen", soft_hyphen_from);
    md.block.ruler.before("html_block", "html_block", html_block);
    // Patch parser to detect <span></span> tags and convert into inline_styles marks
    // Warning... this might be a little brittle
    var parser = new markdown.MarkdownParser(schema, md, __assign(__assign({}, markdown.defaultMarkdownParser.tokens), { anchor: {
            node: "anchor",
            getAttrs: function (tok) { return ({
                value: tok.attrGet("value")
            }); }
        }, fence: {
            block: "code_block",
            getAttrs: function (tok) { return ({ params: tok.info || "" }); },
            noCloseToken: true
        }, soft_hyphen: { node: "soft_hyphen" }, table: { block: "table" }, th: {
            block: "table_header",
            getAttrs: function (tok) { return ({
                style: tok.attrGet("style")
            }); }
        }, tr: { block: "table_row" }, td: {
            block: "table_cell",
            getAttrs: function (tok) { return ({
                style: tok.attrGet("style")
            }); }
        } }));
    parser.tokenHandlers.html_inline = function (state, token) {
        if (!token || !token.content) {
            return;
        }
        var content = (token.content || "").trim();
        if (content.startsWith("<span") && content.endsWith(">")) {
            var dom = new DOMParser().parseFromString(token.content, "text/html");
            var tag = dom.body.firstChild;
            if (!tag) {
                return;
            }
            if (tag.nodeName.toLowerCase() === "span") {
                var className = tag.getAttribute("class");
                state.openMark(schema.marks.inline_styles.create({
                    class: className
                }));
            }
        }
        else if (content === "</span>") {
            state.closeMark(schema.marks.inline_styles);
        }
        else if (content.startsWith("<a") && content.endsWith(">")) {
            var dom = new DOMParser().parseFromString(token.content, "text/html");
            var tag = dom.body.firstChild;
            if (!tag) {
                return;
            }
            if (tag.nodeName.toLowerCase() === "a") {
                var id = tag.getAttribute("id");
                if (id != null) {
                    state.addNode(schema.nodes.anchor, {
                        value: id
                    });
                }
            }
        }
        else if (content === "<br>") {
            state.addNode(schema.nodes.hard_break);
        }
    };
    var alignedParagraphTypes = new Map([
        ["P", "paragraph"],
        ["H1", "heading"],
        ["H2", "heading"],
        ["H3", "heading"],
        ["H4", "heading"],
        ["H5", "heading"],
        ["H6", "heading"]
    ]);
    var alignmentClasses = new Map([
        ["amp-align-left", "left"],
        ["amp-align-center", "center"],
        ["amp-align-right", "right"],
        ["amp-align-justify", "justify"]
    ]);
    // tslint:disable-next-line
    parser.tokenHandlers.html_block_open = function (state, token) {
        if (!alignedParagraphTypes.has(token.meta.tag)) {
            return;
        }
        var styleAttr = token.meta.attrs.find(function (attr) { return attr.name === "style"; });
        var alignAttr = "left";
        if (styleAttr) {
            alignAttr =
                styleAttr.ownerElement.style.textAlign || alignAttr;
        }
        var classAttr = token.meta.attrs.find(function (attr) { return attr.name === "class"; });
        if (classAttr) {
            // Styles may be present in classes instead
            classAttr.ownerElement.classList.forEach(function (value) {
                var asDefault = getDefaultClass(value, options);
                alignAttr = alignmentClasses.get(asDefault) || "left";
            });
        }
        var nodeType = alignedParagraphTypes.get(token.meta.tag);
        var level = nodeType === "heading" ? Number(token.meta.tag[1]) : undefined;
        state.openNode(schema.nodes[nodeType], {
            align: alignAttr ? alignAttr : "left",
            level: level
        });
    };
    // tslint:disable-next-line
    parser.tokenHandlers.html_block_close = function (state, token) {
        if (alignedParagraphTypes.has(token.meta.tag)) {
            state.closeNode();
        }
    };
    return parser;
}
