import React from "react";

export const SparklesIcon = (props: any) => {
  const { readOnly, style, variant } = props;
  return variant === "content-studio" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g
        stroke={readOnly ? "#D9D9D9" : "#F88B8B"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_2281_13381)"
      >
        <path d="M12.417 9.834h-11M1.417 4.5H17.5M7.417 15.166h-6M1.417 20.5h6M21.333 22.25a1.917 1.917 0 011.917 1.917 1.917 1.917 0 011.917-1.916 1.917 1.917 0 01-1.917-1.917 1.917 1.917 0 01-1.917 1.917zm0-11.5a1.917 1.917 0 011.917 1.917 1.917 1.917 0 011.917-1.916 1.917 1.917 0 01-1.917-1.917 1.917 1.917 0 01-1.917 1.917zm-5.708 11.5a5.75 5.75 0 015.75-5.75 5.75 5.75 0 01-5.75-5.75 5.75 5.75 0 01-5.75 5.75 5.75 5.75 0 015.75 5.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_2281_13381">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      style={{ flex: "0 0 auto", ...style }}
      fill={readOnly ? "#D9D9D9" : "#F88B8B"}
      viewBox="5.34 5.34 21.33 21.33"
    >
      <path d="M19.022 7.887a.578.578 0 0 1 1.098 0l.97 2.644c.479 1.303 1.455 2.333 2.69 2.837l2.51 1.024c.5.205.5.952 0 1.157l-2.51 1.024c-1.235.504-2.212 1.534-2.69 2.836l-.97 2.645a.578.578 0 0 1-1.098 0l-.97-2.645c-.479-1.302-1.455-2.332-2.69-2.836l-2.509-1.024c-.501-.205-.501-.952 0-1.157l2.508-1.024c1.236-.504 2.212-1.534 2.69-2.837l.971-2.644ZM11.252 18.767a.289.289 0 0 1 .549 0l.567 1.544a2.42 2.42 0 0 0 1.345 1.419l1.465.597c.25.103.25.477 0 .579l-1.465.598a2.42 2.42 0 0 0-1.345 1.418l-.567 1.545a.289.289 0 0 1-.549 0l-.567-1.545a2.42 2.42 0 0 0-1.345-1.418l-1.465-.598c-.25-.102-.25-.476 0-.579l1.465-.597a2.42 2.42 0 0 0 1.345-1.419l.567-1.544ZM8.9 5.533a.289.289 0 0 1 .548 0l.567 1.545a2.42 2.42 0 0 0 1.345 1.418l1.465.598c.251.102.251.476 0 .579l-1.464.597a2.42 2.42 0 0 0-1.346 1.419l-.567 1.544a.289.289 0 0 1-.548 0l-.567-1.544a2.42 2.42 0 0 0-1.345-1.419l-1.465-.597c-.25-.103-.25-.477 0-.579l1.465-.598a2.42 2.42 0 0 0 1.345-1.418L8.9 5.533Z" />
    </svg>
  );
};
